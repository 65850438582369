.admin-upload {
  //   border: 2px solid red;

  & .upload-top-nav {
    display: flex;
    // border: 2px solid blue;
    // gap: 50px;
    border-bottom: 1px solid #c6c6c6;
    height: fit-content;

    & button {
      font-size: 14px;
      font-family: Inter;
      padding: 10px 34px;
      cursor: pointer;
      border: none;
      background: transparent;
    }

    & .active {
      border-bottom: 2px solid #062644;
    }
  }

  & .upload-bottom {
    & .select {
      margin-top: 26px;
      display: flex;
      gap: 15px;
      align-items: center;

      & .input {
        display: flex;
        position: relative;
        width: 479px;

        & input {
          opacity: 0;
          position: absolute;
          left: 0;
          height: 100%;
          width: 100%;
          cursor: pointer;
        }

        & label {
          // border: 2px solid red;
          height: 100%;
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 12px;
          background-color: #f4f4f4;
          border-radius: 10px;
          color: #a8a8a8;
          font-size: 14px;
          line-height: 22px;

          & img {
            height: 24px;
            width: 24px;
          }
        }
      }

      & img {
        height: 30px;
        width: 30px;
        cursor: pointer;
      }

      & .disabled {
        opacity: 0.5;
      }
    }
  }
}
