.bio-page {
  display: flex;
  // border: 2px solid red;
  flex-direction: column;
  margin: 308px auto 128px;

  .btn-container {
    width: 70%;
    margin: 0 auto;
  }

  & button {
    width: fit-content;
    padding: 20px 35px;
    background: #062644;
    color: white;
    font-size: 25px;
    border: none;
    border-radius: 10px;
    cursor: pointer;
  }

  & .section-center {
    // border: 2px solid blue;
    display: flex;
    gap: 72px;
    justify-content: center;
    // align-items: center;
    width: 70%;
    margin: 70px auto;

    & .left {
      margin: 0 auto;
      // max-width: 364px;
      // width: 90%;

      & img {
        // width: 364px;
        width: 270px;
        // width: 100%;
        height: 417.83px;
        // height: 517.83px;
        object-fit: cover;
        max-width: 1266px;
        border-radius: 10px;
        margin: 0 auto;
      }
    }

    & .right {
      width: fit-content;
      width: 60%;

      & .desc {
        display: flex;
        flex-direction: column;
        gap: 20px;

        & p {
          font-family: "Open Sans";
          font-style: normal;
          font-weight: 400;
          font-size: 17px;
          line-height: 27px;
          display: flex;
          align-items: center;
          text-align: justify;

          color: #062644;
          max-width: 720px;
        }
      }

      & h2 {
        font-family: "Open Sans";
        font-style: normal;
        font-weight: 400;
        font-size: 32px;
        line-height: 107%;
        /* or 34px */

        color: #38b6ff;
        margin-top: 40px;
      }

      & h3 {
        font-family: "Open Sans";
        font-style: normal;
        font-weight: 300;
        font-size: 24px;
        line-height: 33px;
        /* identical to box height */

        color: #152344;
        margin-top: 10px;
      }
    }
  }
}
