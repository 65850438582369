.contact {
  margin-bottom: 193px;

  & .contact-section-two {
    position: relative;
    z-index: 3;
  }

  & .map-item {
    // border: 2px solid red;
    display: flex;
    flex-direction: column;
    gap: 50px;
    justify-content: center;
    align-items: center;

    & .heading {
      display: flex;
      flex-direction: column;
      gap: 18px;
      justify-content: center;
      align-items: center;
      margin-top: 40px;

      @media (max-width: $breakpoint-tablet) {
        max-width: 343px;
      }

      & h1 {
        color: #474747;
        text-align: center;
        font-family: Open Sans;
        font-size: 50px;
        font-style: normal;
        font-weight: 600;
        // line-height: 53.5px; /* */

        @media (max-width: $breakpoint-tablet) {
          font-size: 27px;
        }
      }

      & span {
        background: #474747;
        width: 85px;
        height: 1px;
        // transform: rotate(90deg);
      }

      & p {
        color: #000;
        text-align: center;
        font-family: Open Sans;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: 21.4px; /*  */
        max-width: 538px;
      }
    }

    & .bottom {
      // border: 2px solid red;
      display: flex;
      max-width: 1200px;
      background: #062644;
      height: 590px;
      width: 100%;

      @media (max-width: $breakpoint-tablet) {
        flex-direction: column;
        height: fit-content;
      }

      & .left {
        padding: 60px 50px;
        display: flex;
        flex-direction: column;

        @media (max-width: $breakpoint-tablet) {
          padding: 35px 20px;
          width: 100%;
        }

        & .item {
          display: flex;
          flex-direction: column;
          gap: 20px;

          & h2 {
            color: #fff;
            font-family: Open Sans;
            font-size: 26px;
            font-style: normal;
            font-weight: 400;
            line-height: 36px; /* 138.462% */
          }

          & .text {
            width: 500px;
            display: flex;
            flex-direction: column;
            gap: 10px;

            @media (max-width: $breakpoint-tablet) {
              //  border: 2px solid blue;
              width: 100%;
            }

            & p {
              color: #fff;
              font-family: Inter;
              font-size: 20px;
              font-style: normal;
              font-weight: 400;
              line-height: 25px; /* 125% */

              @media (max-width: $breakpoint-tablet) {
                font-size: 18px;
              }
            }
          }
        }

        & .title {
          margin-bottom: 82px;
        }

        & .telephone {
          margin-bottom: 40px;
          display: flex;
          flex-direction: column;
          gap: 5px;

          & .text {
            & p {
              color: #fff;
              font-family: Open Sans;
              font-size: 22px;
              font-style: normal;
              font-weight: 400;
              line-height: 30px; /* 136.364% */

              @media (max-width: $breakpoint-tablet) {
                font-size: 18px;
              }
            }
          }
        }

        & .email {
          gap: 15px;

          & .text {
            & a {
              color: #fff;
              font-family: Open Sans;
              font-size: 20px;
              font-style: normal;
              font-weight: 400;
              line-height: 18px; /* 90% */
              letter-spacing: 1px;
              text-decoration-line: underline;
              text-transform: lowercase;
              text-decoration: underline;

              @media (max-width: $breakpoint-tablet) {
                font-size: 18px;
              }
            }
          }
        }
      }

      & .right {
        height: 100%;
        // border: 2px solid red;

        & img {
          height: 100%;
          width: 100%;
          object-fit: cover;
        }
      }
    }
  }

  & .contact-section-four {
    & .bottom {
      & .title {
        // margin-bottom: 82px;

        & .text {
          gap: 25px;
          //   border: 2px solid red;
        }
      }
    }

    & .map-bottom {
      @media (max-width: $breakpoint-tablet) {
        flex-direction: column-reverse;
      }
    }
  }

  & .contact-section-three {
    background: #062644;
    height: 789px;
    // width: 80%;
    width: 1200px;
    margin: 51px auto;

    padding: 40px 30px;
    display: flex;
    flex-direction: column;
    gap: 20px;

    @media (max-width: $breakpoint-tablet) {
      // flex-direction: column;
      height: fit-content;
      width: 100%;
    }

    & h1 {
      color: #fff;
      font-family: Inter;
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: 27px; /* 135% */
      letter-spacing: 1px;
      text-transform: uppercase;
    }

    & .content {
      // border: 2px solid red;
      display: flex;
      gap: 12px;
      justify-content: space-between;

      @media (max-width: $breakpoint-tablet) {
        flex-direction: column;
      }

      & .line {
        width: 1px;
        background: #fff;
        opacity: 0.20000000298023224;
      }

      & .row {
        display: flex;
        flex-direction: column;
        gap: 24px;

        & .block {
          display: flex;
          flex-direction: column;
          gap: 20px;

          @media (max-width: $breakpoint-tablet) {
            flex-direction: column;
            gap: 0px;
          }

          & h3 {
            color: #fff;
            font-family: Inter;
            font-size: 21px;
            font-style: normal;
            font-weight: 400;
            line-height: 32px; /* 152.381% */
          }

          & .details {
            display: flex;
            gap: 58px;

            @media (max-width: $breakpoint-tablet) {
              flex-direction: column;
              gap: 0px;
            }

            & p {
              color: #fff;
              font-family: Inter;
              font-size: 11px;
              font-style: normal;
              font-weight: 400;
              line-height: 18px; /* 163.636% */
              letter-spacing: 1px;
              text-transform: uppercase;
            }

            & .send {
              display: flex;
              flex-direction: column;
              gap: 11px;

              @media (max-width: $breakpoint-tablet) {
                gap: 0px;
              }

              & p {
                color: #fff;
                font-family: Inter;
                font-size: 18px;
                font-style: normal;
                font-weight: 400;
                line-height: 30px; /* 136.364% */
              }

              & h4,
              a {
                color: #fff;
                font-family: Inter;
                font-size: 16px;
                font-style: normal;
                font-weight: 700;
                line-height: 24px; /* 150% */
                text-decoration: none;
                cursor: pointer;
              }
            }
          }
        }
      }
    }
  }

  & .section-five {
    display: flex;
    margin-top: 90px;
    flex-direction: column;
    gap: 40px;
    justify-content: center;
    align-items: center;

    & h1 {
      color: #474747;
      text-align: center;
      font-family: Open Sans;
      font-size: 50px;
      font-style: normal;
      font-weight: 600;
      line-height: 107%; /* 53.5px */

      @media (max-width: $breakpoint-tablet) {
        font-size: 27px;
      }
    }

    & span {
      width: 85px;
      height: 1px;
      background: #474747;
    }

    & form {
      width: clamp(500px, 80%, 1104px);
      display: flex;
      flex-direction: column;
      gap: 24px;

      @media (max-width: $breakpoint-tablet) {
        width: 80%;
      }

      & input,
      textarea {
        border-radius: 5px;
        border: 1px solid #e0e0e0;
        background: #f3f3f3;
        padding: 15px;

        &::placeholder {
          color: #000;
          text-align: justify;
          font-family: Kozuka Gothic Pro;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }
      }

      & textarea {
        height: 243px;
        resize: none;
      }

      & .top {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(40%, 541px));
        // grid-template-columns: repeat(auto-fit, clamp(400px, 100%, 541px));
        gap: 22px;
      }

      & button {
        background: #152344;
        color: #fff;
        font-family: Open Sans;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: 107%; /* 21.4px */
        padding: 22px;
        border: none;
      }
    }
  }
}
