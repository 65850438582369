.section-one {
  height: fit-content;
  position: relative;
  overflow: hidden;

  & .section-container {
    & .main {
      display: flex;
      overflow-x: visible;
      transition: var(--transition);
    }

    & .translated {
      transform: translateX(-100%);
      transition: var(--transition);
    }

    & .main-bg {
      position: relative;
      width: 100vw;

      @media (max-width: $breakpoint-large) {
        height: 565px;
      }

      & img {
        // width: 100%;
        width: 100vw;
        height: 100%;
        object-fit: cover;
      }

      & .bg-label {
        position: absolute;
        top: 50%;
        left: 0;
        background: rgba(6, 38, 68, 0.6);
        border-radius: 0px 148px 148px 0px;
        max-width: 970px;
        width: 50%;
        max-height: 430px;
        height: 40%;
        // transform: translateY(-215px);
        transform: translateY(-25%);
        display: flex;
        align-items: flex-end;
        flex-direction: column;
        justify-content: center;
        // padding-left: 60%;
        padding: 0 90px;

        @media (max-width: $breakpoint-tablet) {
          max-height: 168px;
          width: 90%;
          max-width: 475px;
          padding: 0 60px;
        }

        & h1 {
          font-family: "Open Sans";
          font-style: normal;
          font-weight: 700;
          font-size: 64px;
          line-height: 87px;
          display: flex;
          align-items: center;
          letter-spacing: -0.095em;

          color: #ffffff;

          @media (max-width: $breakpoint-tablet) {
            font-family: "Open Sans";
            font-style: normal;
            font-weight: 700;
            font-size: 40px;
            line-height: 54px;
            display: flex;
            align-items: center;
            letter-spacing: -0.095em;
          }
          @media (max-width: $breakpoint-mobile) {
            font-size: 30px;
          }
        }

        & h3 {
          font-family: "Open Sans";
          font-style: normal;
          font-weight: 600;
          font-size: 36px;
          line-height: 49px;
          letter-spacing: -0.095em;

          color: #ffffff;

          @media (max-width: $breakpoint-tablet) {
            font-family: "Open Sans";
            font-style: normal;
            font-weight: 600;
            font-size: 24px;
            line-height: 33px;
            /* identical to box height */

            display: flex;
            align-items: center;
            letter-spacing: -0.095em;
          }
          @media (max-width: $breakpoint-mobile) {
            font-size: 20px;
          }
        }
      }
    }

    & .world {
      & .bg-label {
        right: 0;
        left: unset;

        border-radius: 148px 0px 0px 148px;
        // padding: 0;
        align-items: flex-start;

        & h3 {
          max-width: 500px;
          font-family: "Open Sans";
          font-style: normal;
          font-weight: 600;
          font-size: 64px;
          line-height: 87px;
          display: flex;
          align-items: center;

          @media (max-width: $breakpoint-tablet) {
            font-family: "Open Sans";
            font-style: normal;
            font-weight: 600;
            font-size: 24px;
            line-height: 33px;
            /* identical to box height */

            display: flex;
            align-items: center;
            letter-spacing: -0.095em;
          }
          @media (max-width: $breakpoint-mobile) {
            font-size: 20px;
          }
        }
      }
    }
  }

  & .left-button {
    position: absolute;
    top: calc(50% + 50px);
    // top: 460px;
    left: 137px;

    z-index: 3;
    cursor: pointer;

    @media (max-width: $breakpoint-large) {
      top: 302px;
      left: 40px;
      height: 565px;
    }
    @media (max-width: $breakpoint-tablet) {
      left: 20px;

      & img{
        width: 25px;
        height: 25px;
      }
    }
  }

  & .right-button {
    position: absolute;
    // top: 460px;
    top: calc(50% + 50px);
    // top: 50%;
    right: 137px;

    z-index: 3;
    cursor: pointer;

    @media (max-width: $breakpoint-large) {
      top: 302px;
      right: 40px;
    }

    @media (max-width: $breakpoint-tablet) {
      right: 20px;

      & img{
        width: 25px;
        height: 25px;
      }
    }
  }
}
