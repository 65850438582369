.csr {
  overflow-x: hidden;
  position: relative;

  & .csr-section-one {
    height: 100vh;
    max-height: 880px;
    display: flex;
    align-items: flex-end;
    padding-bottom: 205px;
    justify-content: center;
    position: relative;
    z-index: 3;

    @media (max-width: $breakpoint-tablet) {
      padding-bottom: 65px;
      max-height: 565px;
    }

    & .center {
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      gap: 23px;

      @media (max-width: $breakpoint-tablet) {
        max-width: 317px;
      }

      & h3 {
        color: #fff;
        text-align: center;
        font-family: Open Sans;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
      }

      & h1 {
        color: #062644;
        font-family: Open Sans;
        font-size: 32px;
        font-style: normal;
        font-weight: 600;
        line-height: 107%; /* 34.24px */
        padding: 28px 83px;
        border-radius: 148px;
        background: #fff;
        text-align: center;

        @media (max-width: $breakpoint-tablet) {
          font-size: 24px;
          padding: 25px 45px;
          margin: 0 auto;
        }
      }

      & p {
        max-width: 581px;
        color: #fff;
        text-align: center;
        font-family: Open Sans;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-align: center;
        width: fit-content;
        margin: 0 auto;
      }
    }
  }

  & .csr-section-two {
    // border: 2px solid red;
    max-width: 1483px;
    width: 80%;
    margin: 0 auto;
    padding: 80px 0;
    position: relative;
    z-index: 3;

    & .content {
      //   border: 2px solid blue;
      width: 100%;
      max-width: 1483px;
      margin: 0 auto;

      display: flex;
      gap: 73px;

      @media (max-width: $breakpoint-tablet) {
        flex-direction: column;
      }

      & .left {
        position: relative;

        & img {
          // width: 686px;
          // height: 603px;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
        & .play {
          position: absolute;
          top: 50%;
          left: 50%;
          //   top: 256px;
          //   left: 257px;
          // transform: translate();
          margin-top: -46px;
          margin-left: -46px;
          width: 92px;
          height: 92px;
        }
      }

      & .right {
        display: flex;
        flex-direction: column;
        justify-content: center;
        // align-items: center;
        gap: 40px;

        & h1 {
          color: #474747;
          font-family: Open Sans;
          font-size: 50px;
          font-style: normal;
          font-weight: 600;
          line-height: 107%; /* 53.5px */
          max-width: 724px;

          @media (max-width: $breakpoint-tablet) {
            font-size: 27px;
          }
        }

        & span {
          background: #474747;
          width: 85px;
          height: 1px;

          @media (max-width: $breakpoint-tablet) {
            // font-size: 27px;
            display: none;
          }
        }

        & p {
          color: #000;
          text-align: justify;
          font-family: Open Sans;
          font-size: 20px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          max-width: 724px;
        }

        & a {
          width: 186px;
          padding: 0;
          height: 69px;
          display: flex;
          border-radius: 25px;
          background: var(--primary-blue);
          color: white;
          justify-content: center;
          align-items: center;
          font-family: "Open Sans";
          font-style: normal;
          font-weight: 400;
          font-size: 17px;
          line-height: normal;
          border: none;
          transition: var(--transition-fast);
          cursor: pointer;
          border: 1px solid var(--primary-blue);
          animation: mymove 5s infinite;
          position: relative;
          // border: 2px solid red;

          &:hover {
            background: white;
            transition: var(--transition-fast);
            border: 1px solid var(--primary-blue);
            color: var(--primary-blue);
          }

          & span {
            z-index: 4;
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            background: transparent;
          }

          & .liquid {
            // height: 100%;
            width: fit-content;
            // width: 186px;
            padding: 0;
            height: 69px;
          }
        }
      }
    }
  }

  & .csr-section-three {
    padding-bottom: 80px;
    position: relative;
    z-index: 3;
    & .content {
      width: 80%;
      max-width: 1408px;
      //   border: 2px solid blue;
      margin: 0 auto;

      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;

      & .csr-item {
        display: flex;
        flex-direction: column;
        gap: 30px;
        padding: 0 50px;
        width: 277px;
        height: 216px;
        justify-content: center;
        align-items: center;
        cursor: pointer;

        @media (max-width: $breakpoint-tablet) {
          // border-right: none;;
          background: #fff;
        }

        &:hover {
          background: #e62d39;

          & p {
            color: #fff;
          }
        }

        & img {
          width: 48px;
          height: 48px;
        }
        & p {
          min-width: 277px;
          text-align: center;
        }
      }

      & .border {
        border-right: 0.6px solid rgba(0, 0, 0, 0.55);

        @media (max-width: $breakpoint-tablet) {
          border-bottom: 0.6px solid rgba(0, 0, 0, 0.55);
          border-right: none;
        }
      }
    }

    & .modal-content {
      background: #a3a2a3;
      padding: 80px 0;
      color: white;
      flex-direction: column;
      align-items: center;
      gap: 10px;
      position: relative;

      & .image {
        position: absolute;
        top: 30px;
        right: 30px;
        cursor: pointer;
      }

      & h1 {
        width: 100%;
        max-width: 80%;
      }

      & p {
        max-width: 80%;
        color: #fff;
        text-align: justify;
        font-family: Open Sans;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }
  }

  & .csr-section-four {
    background: #062643;
    padding: 107px;
    display: flex;
    justify-content: center;
    align-items: center;

    & .center {
      //   border: 2px solid red;
      display: flex;
      flex-direction: column;
      gap: 29px;
      max-width: 1320px;
      margin: 0 auto;

      & h1 {
        color: #fff;
        font-family: Open Sans;
        font-size: 50px;
        font-style: normal;
        font-weight: 600;
        line-height: 107%; /* 53.5px */
        text-align: center;

        @media (max-width: $breakpoint-tablet) {
          font-size: 27px;
          width: 294px;
          margin: 0 auto;
        }
      }

      & .line {
        display: flex;
        justify-content: center;
        align-items: center;
        & span {
          width: 138.33px;
          height: 5.77px;
          background: #a5a4a5;
          display: block;
        }

        & .red {
          background: #e62d39;
        }
      }

      & .goals {
        // display: flex;
        // flex-wrap: wrap;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 40px;
        row-gap: 29px;
        justify-content: center;

        @media (max-width: $breakpoint-tablet) {
          grid-template-columns: 1fr;
        }

        & .goal {
          border-radius: 7.828px;
          width: 400px;
          height: 400px;
          background-repeat: no-repeat;
          display: flex;
          align-items: center;
          justify-content: center;
          transition: var(--transition);

          @media (max-width: $breakpoint-tablet) {
            width: 100%;
          }

          & .goal-center {
            padding: 39px;
            border-radius: 7.828px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: 256px;

            @media (max-width: $breakpoint-tablet) {
              width: 80%;
              height: 75%;
            }

            & img {
              height: 178px;
              width: 178px;
              object-fit: cover;
            }

            & .text {
              display: flex;
              gap: 12px;
              align-items: center;
              justify-content: space-between;
              width: fit-content;
              margin: 0 auto;
              //   border: 2px solid blue;
              gap: 8px;
              & span {
                color: #fff;
                font-family: Open Sans;
                font-size: 50.098px;
                font-style: normal;
                font-weight: 600;
                line-height: 107%; /* 53.605px */
              }

              & p {
                color: #fff;
                font-family: Open Sans;
                font-size: 18.787px;
                font-style: normal;
                font-weight: 600;
                line-height: 107%; /* 20.102px */
                text-align: left;
                width: auto;
                min-width: 91px;
                // border: 2px solid green;
                // display: inline-block;
                float: right;
              }
            }
          }

          &:hover {
            transition: var(--transition);
            & .text {
              & span,
              p {
                color: #474747;
              }
            }
          }
        }
      }
    }
  }
  & .get-involved {
    background: #fff;
    padding: 80px;
    display: flex;
    align-items: center;
    justify-content: center;

    @media (max-width: $breakpoint-tablet) {
    }

    & a {
      text-decoration: none;
      padding: 32px 50px;
      border-radius: 59px;
      background: #ed1c24;
      color: #fff;
      font-family: Open Sans;
      font-size: 30px;
      font-style: normal;
      font-weight: 800;
      line-height: 107%; /* 32.1px */
      border: none;
      cursor: pointer;
      width: fit-content;

      @media (max-width: $breakpoint-tablet) {
        min-width: 300px;

        // padding: 32px 51px;
      }
    }
  }
}
