.resources {
  overflow-x: hidden;
  position: relative;

  & .section-one {
    // border: 2px solid red;
    background: white;
    max-width: 1200px;
    padding: 100px 0;
    margin: 0 auto;

    & .content-head-center {
      margin: 0 auto;
      width: var(--width-standard);
      min-height: 400px;
      display: grid;
      grid-template-columns: auto 1fr;
      gap: 40px;

      @media (max-width: $breakpoint-large) {
        grid-template-columns: 1fr;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: fit-content;
        gap: 30px;
      }

      @media (max-width: $breakpoint-tablet) {
        gap: 20px;
      }

      & .left {
        width: 400px;

        @media (max-width: $breakpoint-large) {
          width: 700px;
          height: 400px;
          margin: 0 auto;
        }
        @media (max-width: $breakpoint-tablet) {
        }

        & .all {
          display: flex;
          flex-direction: column;
          gap: 20px;
          justify-content: center;
          align-items: center;
          padding: 50px;
          background: var(--primary-blue);
          color: white;
          border-radius: 10px;

          @media (max-width: $breakpoint-large) {
            gap: 30px;
            padding: 40px;
          }
          @media (max-width: $breakpoint-tablet) {
            gap: 20px;
            padding: 30px;
          }

          & span {
            border: 1px solid white;
            border-radius: 50%;
            height: 140px;
            width: 140px;
            font-size: 50px;
            display: flex;
            justify-content: center;
            align-items: center;

            @media (max-width: $breakpoint-large) {
              font-size: 45px;
              height: 120px;
              width: 120px;
            }
            @media (max-width: $breakpoint-tablet) {
              font-size: 40px;
              height: 100px;
              width: 100px;
            }
          }

          & .text {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            gap: 15px;
            & h2 {
              font-size: 30px;

              @media (max-width: $breakpoint-large) {
                font-size: 25px;
              }
              @media (max-width: $breakpoint-tablet) {
                font-size: 20px;
              }
            }

            & p {
              font-size: 25px;
              text-transform: capitalize;

              @media (max-width: $breakpoint-large) {
                font-size: 20px;
              }
              @media (max-width: $breakpoint-tablet) {
                font-size: 17px;
              }
            }
          }
          & button {
            font-size: 20px;
            text-transform: uppercase;
            background: white;
            border-radius: 30px;
            height: 60px;
            cursor: pointer;
            border: none;
            display: flex;
            align-items: center;
            justify-content: center;

            @media (max-width: $breakpoint-large) {
              height: 55px;
              font-size: 18px;
            }
            @media (max-width: $breakpoint-tablet) {
              height: 50px;
              font-size: 15px;
            }

            & a {
              display: flex;
              justify-content: center;
              align-items: center;
              padding: 0 30px;
              height: 60px;
              text-decoration: none;
              color: var(--primary-blue);
            }
          }
        }
      }

      & .right {
        // border: 2px solid blue;
        // width: 500px;
        // width: 100%;

        & .items {
          display: grid;
          grid-template-columns: 1fr 1fr;
          gap: 40px;
          grid-auto-rows: auto;
          row-gap: 50px;

          @media (max-width: $breakpoint-large) {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
          }
          @media (max-width: $breakpoint-tablet) {
          }

          & .item {
            display: flex;
            flex-direction: row;
            gap: 20px;
            justify-content: space-between;
            padding: 13px 20px 60px;
            box-shadow: 1px 1px 12px 1px rgb(240, 238, 238);
            border-radius: 15px;
            transition: var(--transition);
            text-decoration: none;
            // background: var(--primary-blue);
            background: white;
            color: var(--primary-blue);
            position: relative;
            width: 335px;

            @media (max-width: $breakpoint-large) {
              margin: 0 auto;
            }

            & span {
              font-size: 45px;
              background: var(--primary-blue);
              z-index: 3;
              color: white;
              transform: translateY(-40px);
              width: 100px;
              height: 100px;
              display: flex;
              align-items: center;
              justify-content: center;
              border-radius: 5px;
              box-shadow: 1px 1px 15px 1px rgb(159, 158, 158);

              @media (max-width: $breakpoint-large) {
                font-size: 40px;
                width: 80px;
                height: 80px;
              }
              @media (max-width: $breakpoint-tablet) {
                font-size: 35px;
                width: 70px;
                height: 70px;
              }
            }

            & .text {
              display: flex;
              flex-direction: column;
              gap: 10px;
              align-items: flex-start;
              // border: 2px solid blue;
              // text-align: ;
              width: 150px;

              & h2 {
                text-transform: capitalize;
                z-index: 3;
                // border: 2px solid red;
                font-size: 16px;
                text-align: left;
                height: 60px;

                @media (max-width: $breakpoint-large) {
                  font-size: 25px;
                }
                @media (max-width: $breakpoint-tablet) {
                  font-size: 20px;
                }
              }

              & p {
                z-index: 3;
              }
            }

            &:before {
              content: "";
              border-radius: 15px;
              position: absolute;
              height: 100%;
              width: 100%;
              top: 0;
              right: 0;
              transform: scaleX(0);
              transform-origin: right;
              // background-color: var(--primary-blue);
              background-color: white;
              transition: transform 0.5s ease 0s;
            }

            &:hover {
              cursor: pointer;
              // box-shadow: none;
              transition: var(--transition);
              // border: 2px solid red;
              // color: var(--primary-blue);

              &:before {
                top: 0;
                // transform: scaleX(1);
                transform-origin: left;
              }
            }
          }
        }
      }
    }
  }
}

.resource-details {
  background: rgb(239, 241, 242);
  & .section-two {
    max-width: 1200px;
    margin: 0 auto;
    // border: 2px solid red;
    padding: 100px 40px;

    & .back-btn {
      border: none;
      background: #062644;
      color: white;
      border-radius: 10px;
      text-align: center;
      padding: 15px 25px;
      font-size: 20px;
      text-decoration: none;
      cursor: pointer;
      width: fit-content;
      display: flex;
      align-items: center;
      // justify-content: space-between;
      gap: 10px;
    }

    & .content-center {
      min-height: 400px;
      // border: 2px solid blue;
      max-width: var(--width-standard);
      margin: 50px auto;
      border-radius: 20px;

      & .content {
        // border: 2px solid green;
        box-shadow: 1px 2px 15px 2px rgb(219, 217, 217);

        background: white;
        display: flex;
        border-radius: 20px;

        & .right {
          min-width: 200px;
          background: white;
          border-top-left-radius: 20px;
          border-bottom-left-radius: 20px;
          // border: 2px solid red;
          padding: 20px;
          display: flex;
          flex-direction: column;
          gap: 30px;

          @media (max-width: $breakpoint-large) {
            min-width: auto;
          }
          @media (max-width: $breakpoint-tablet) {
            padding: 18px;
          }
          @media (max-width: $breakpoint-mobile) {
            padding: 15px;
          }

          & .sub-item {
            display: flex;
            flex-direction: column;
            gap: 20px;

            & .heading {
              display: flex;
              gap: 30px;
              padding: 20px;
              cursor: pointer;
              align-items: center;

              & h2 {
                text-transform: capitalize;
                color: rgb(162, 162, 162);
                font-size: 20px;
              }
            }

            & .bottom {
              & a {
                padding-left: 40px;
              }
            }
          }

          & .item {
            display: flex;
            gap: 20px;
            color: rgb(162, 162, 162);
            padding: 20px;
            cursor: pointer;
            text-decoration: none;

            // border: 2px solid red;

            @media (max-width: $breakpoint-tablet) {
              padding: 18px;
            }
            @media (max-width: $breakpoint-mobile) {
              padding: 15px;
            }

            & span {
              font-size: 20px;

              @media (max-width: $breakpoint-tablet) {
                font-size: 18px;
              }
              @media (max-width: $breakpoint-mobile) {
                font-size: 16px;
              }
            }
            & h2 {
              font-size: 16px;
              text-transform: capitalize;

              @media (max-width: $breakpoint-large) {
                display: none;
              }
              @media (max-width: $breakpoint-tablet) {
              }
            }
          }

          & .active {
            background: var(--primary-blue);
            color: white;
            border-radius: 15px;
          }
        }

        & .left {
          // border: 2px solid brown;
          // height: 720px;
          height: auto;
          // min-width: 100%;
          // width: fit-content;
          width: 100%;
          padding: 20px 40px;
          background: rgb(242, 243, 247);
          border-top-left-radius: 40px;
          display: flex;
          flex-direction: column;
          gap: 40px;
          overflow-y: scroll;

          & .top {
            // border: 2px solid red;
            padding: 20px 0;
            font-size: 16px;
            display: grid;
            // flex-wrap: wrap;
            // gap: 20px;
            align-items: center;
            height: 70px;
            grid-template-columns: 1fr 1fr 1fr;
            gap: 30px;

            @media (max-width: $breakpoint-tablet) {
              font-size: 14px;
            }
            @media (max-width: $breakpoint-mobile) {
              font-size: 12px;
            }

            & .total {
              display: flex;
              gap: 30px;
              align-items: center;
              width: 250px;
              // border: 2px solid red;

              & span {
                padding: 6px 8px;
                // background: rgb(225, 238, 240);
                background: var(--primary-blue);
                border-radius: 3px;
                color: white;
                width: 15%;
                // border: 1px solid var(--primary-blue);
              }
            }

            & p {
              color: black;
              text-transform: capitalize;
            }

            & h3 {
              // width: 100%;
              // display: flex;
              // justify-content: center;
              text-transform: capitalize;
              font-size: 20px;
              width: 250px;
              // border: 2px solid red;

              & span {
                background: none;
                color: var(--primary-blue);
                padding: none;
              }

              @media (max-width: $breakpoint-large) {
                font-size: 16px;
              }
              @media (max-width: $breakpoint-tablet) {
                font-size: 13px;
              }
            }

            & .button {
              display: flex;
              gap: 20px;
              align-items: center;

              & .label {
                border: none;
                width: fit-content;
                padding: 10px 20px;
                border: 1px solid var(--primary-blue);
                font-size: 15px;
                background: transparent;
                border-radius: 15px;
                transition: var(--transition);

                &:hover {
                  cursor: pointer;
                  background: var(--primary-blue);
                  transition: var(--transition);
                  color: white;
                }
              }

              & select {
                border: 1px solid var(--primary-blue);
                padding: 10px 20px;
                border-radius: 15px;
                font-size: 15px;
                text-transform: capitalize;
              }

              & input {
                border: 1px solid var(--primary-blue);
                background: transparent;
                width: fit-content;
                opacity: 0;
                display: none;
              }
              & .upload-button {
                border: none;
                width: fit-content;
                // padding: 10px 20px;
                // border: 1px solid var(--primary-blue);
                // font-size: 15px;
                // background: transparent;
                // border-radius: 15px;
                transition: var(--transition);
                text-transform: uppercase;
                font-weight: 700;

                &:hover {
                  cursor: pointer;
                  // background: var(--primary-blue);
                  transition: var(--transition);
                  // color: white;
                  opacity: 0.5;
                }
              }
            }
          }

          & .bottom {
            // border: 2px solid red;
            // height: 500px;
            // height: 100%;
            display: flex;
            flex-wrap: wrap;
            // grid-template-columns: repeat(3, 1fr);
            gap: 30px;
            width: 100%;

            @media (max-width: $breakpoint-large) {
              grid-template-columns: repeat(2, 1fr);
              justify-content: center;
            }
            @media (max-width: $breakpoint-tablet) {
              grid-template-columns: repeat(1, 1fr);
            }

            & .item {
              padding: 70px 20px 50px;
              background: white;
              border-radius: 25px;
              display: flex;
              flex-direction: column;
              gap: 30px;
              position: relative;
              cursor: pointer;
              width: 250px;
              text-decoration: none;
              color: var(--primary-blue);

              @media (max-width: $breakpoint-large) {
                width: 220px;
                gap: 10px;
                padding: 60px 25px 40px;
              }
              @media (max-width: $breakpoint-tablet) {
                width: 180px;
                gap: 0px;
                padding: 50px 20px 20px;
              }

              & .icon {
                position: absolute;
                top: -18px;
                left: 40px;
                font-size: 42px;
                background: var(--primary-blue);
                color: white;
                box-shadow: 1px 2px 10px 2px rgb(198, 194, 194);
                border-radius: 50%;
                width: 65px;
                height: 65px;
                display: flex;
                align-items: center;
                justify-content: center;

                @media (max-width: $breakpoint-large) {
                  font-size: 33px;
                  width: 60px;
                  height: 60px;
                }
                @media (max-width: $breakpoint-tablet) {
                  font-size: 30px;
                  width: 52px;
                  height: 52px;
                }
              }

              & h2 {
                font-size: 15px;
                text-transform: capitalize;
                // border: 2px solid red;
                word-wrap: break-word;
                height: 60px;

                @media (max-width: $breakpoint-large) {
                  font-size: 13px;
                }
                @media (max-width: $breakpoint-tablet) {
                  font-size: 11px;
                }
              }

              & p {
                display: flex;
                align-items: center;
                gap: 10px;
                // justify-content: space-between;

                @media (max-width: $breakpoint-large) {
                  font-size: 13px;
                }
                @media (max-width: $breakpoint-tablet) {
                  font-size: 11px;
                }

                & .delete-icon {
                  font-size: 30px;
                }

                & a {
                  color: var(--primary-blue);
                  text-decoration: none;
                  cursor: pointer;
                  text-transform: capitalize;
                  font-size: 15px;
                }
              }
            }

            & .button {
              background: rgb(242, 243, 247);
              border: none;
              display: flex;
              align-items: center;
              justify-content: center;
              cursor: default;

              & span {
                height: 60px;
                border: 1px solid var(--primary-blue);
                padding: 30px;
                font-size: 15px;
                display: flex;
                align-items: center;
                border-radius: 25px;
                justify-content: center;
                cursor: pointer;
                color: white;
                background: var(--primary-blue);
                transition: var(--transition);

                @media (max-width: $breakpoint-tablet) {
                  font-size: 13px;
                  padding: 25px;
                }
                @media (max-width: $breakpoint-mobile) {
                  font-size: 11px;
                  padding: 10px 15px;
                }

                &:hover {
                  background: white;
                  color: var(--primary-blue);
                  transition: var(--transition);
                }
              }
            }
          }
        }
      }
    }
  }

  & .upload-file {
    position: fixed;
    top: 0;
    left: 0;
    background: rgba(128, 128, 128, 0.65);
    width: 100%;
    height: 100%;
    z-index: 999;
    display: flex;
    justify-content: center;
    align-items: center;

    & .content-center {
      background: white;
      min-width: 50%;
      min-height: 50%;
      max-height: 80%;
      overflow-y: scroll;
      padding: 40px;
      display: flex;
      flex-direction: column;
      gap: 30px;
      position: relative;

      & p,
      h2 {
        display: flex;
        justify-content: space-between;

        & span {
          cursor: pointer;
          text-transform: capitalize;
        }
      }

      & .close {
        position: absolute;
        top: 7px;
        right: 20px;
        color: red;
        cursor: pointer;
        font-size: 30px;
      }
    }
  }
}

@media print {
  .academy-pdf {
    display: none;
  }
}
.academy-pdf {
  background: white;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  // border: 2px solid blue;
  z-index: 9999999999999999;
  overflow-y: scroll;

  & .pagination {
    max-width: 1200px;
    // margin-left: 50px;
    // margin-right: 50px;
  }

  & .content {
    // max-width: 1200px;
    margin: 50px auto;
  }
  & .back-btn {
    border: none;
    background: #062644;
    color: white;
    border-radius: 10px;
    text-align: center;
    padding: 15px 25px;
    font-size: 20px;
    text-decoration: none;
    cursor: pointer;
    width: fit-content;
    display: flex;
    align-items: center;
    // justify-content: space-between;
    gap: 10px;
  }

  & .pdf-container {
    // margin-top: 50px;
    // height: 550px;
    // border: 2px solid red;
    width: fit-content;
    // max-width: 1410px;
    margin: 0 auto;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
  }
}
