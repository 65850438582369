.about-section-three {
  height: 594px;
  transition: var(--transition);
  position: relative;

  & .bg-image {
    position: absolute;
    top: 0;
    left: 0;
    height: 594px;
    overflow: hidden;
    width: 100%;

    & img {
      transform: scale(1, 1);
      transition: var(--transition);
      object-fit: cover;
    }

    &:before {
      content: "";
      height: 100%;
      width: 100%;
      position: absolute;
      top: 0;
      background: rgba(217, 217, 217, 0.83);
      left: 0;
      z-index: 3;
    }
  }

  @media (max-width: $breakpoint-tablet) {
    height: fit-content;
  }

  &:hover {
    & .bg-image {
      & img {
        transition: var(--transition);
        transform: scale(1.4, 1.4);
      }
    }
  }

  & .goals-center {
    // background: rgba(217, 217, 217, 0.83);
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 3;
    // border: 2px solid red;

    @media (max-width: $breakpoint-tablet) {
      padding: 60px 73.5px;
    }

    &:hover {
      // transform: scale(1.1);
    }

    // & .bg-image

    & .goals {
      display: flex;
      width: 90%;
      max-width: 1403px;
      justify-content: space-between;
      margin: 0 auto;
      // border: 2px solid red;

      height: fit-content;

      @media (max-width: $breakpoint-tablet) {
        flex-wrap: wrap;
        gap: 30px;
        justify-content: center;
        align-items: center;
      }
    }

    & .goal-container {
      &:hover {
        & .goal {
          transform: rotateY(180deg);
          transition: var(--transition);

          & .main-text {
            display: none;
          }

          & img {
            display: none;
          }

          & .text {
            display: flex;
            transform: rotateY(180deg);
            transition: var(--transition);
            // transform: translateX();
          }

          & .spirit {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            gap: 7px;

            & p {
              text-transform: lowercase;
            }

            & span {
              text-transform: uppercase;
              font-family: "Open Sans";
              font-style: normal;
              font-weight: 800;
              font-size: 20px;
              line-height: 27px;
              display: flex;
              align-items: center;
              text-align: center;

              color: #ffffff;
            }
          }
        }
      }
    }

    & .goal {
      width: 277px;
      height: 296px;
      background: white;
      display: flex;
      flex-direction: column;
      gap: 30px;
      justify-content: center;
      align-items: center;
      transition: var(--transition);

      & .main-text {
        text-transform: capitalize;
      }

      & p {
        font-family: "Open Sans";
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 27px;
        display: flex;
        align-items: center;
        text-align: center;

        color: #062644;
      }

      & .text {
        display: none;
        transform: rotateY(0deg);
        transition: var(--transition);
        padding: 50px 40px;
        width: 100%;
        height: 100%;
        background: #062644;

        & p {
          font-family: "Open Sans";
          font-style: normal;
          font-weight: 400;
          font-size: 20px;
          line-height: 27px;
          display: flex;
          align-items: center;
          color: white;
          text-align: center;
        }
      }
    }
  }
}
