.admin-login {
  display: flex;
  flex-direction: row;
  height: fit-content;
  min-height: 100vh;

  .left {
    width: 50%;
    min-width: 773px;
    // background: linear-gradient(0deg, #2c73eb 0%, #2c73eb 100%);
    background: var(--primary-blue);

    display: flex;
    justify-content: center;
    align-items: center;

    & .center {
      width: 70%;
      display: flex;
      flex-direction: column;
      gap: 30px;

      & h1 {
        color: #fff;
        font-family: Open Sans;
        font-size: 50px;
        font-style: normal;
        font-weight: 600;
        line-height: 107%; /* 53.5px */
      }

      & p {
        color: #fff;
        font-family: Open Sans;
        font-size: 28px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: -3.42px;
      }
    }
  }

  .right {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 200px;
    width: 100%;

    & form {
      width: 50%;
      height: fit-content;
      max-width: 446px;
      // min-height: 557px;
      padding: 40px;
      border-radius: 15px;
      //   border: 2px solid red;
      margin: 0 auto;
      box-shadow: 1px 1px 9px rgb(231, 228, 228);

      & .form-center {
        display: flex;
        flex-direction: column;
        gap: 23px;
        // border: 2px solid blue;

        & h1 {
          color: #000;
          font-family: Inter;
          font-size: 30px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
        }

        & p {
          color: #8692a6;
          font-family: Inter;
          font-size: 18px;
          font-style: normal;
          font-weight: 400;
          line-height: 28px; /* 155.556% */
        }

        & .input-item {
          display: flex;
          flex-direction: column;
          gap: 14px;
          position: relative;

          & label {
            color: #696f79;
            font-family: Inter;
            font-size: 20px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            position: absolute;
            top: 50%;
            left: 20px;
            height: 20px;
            width: 20px;
            transform: translateY(-10px);
          }
          & input {
            border-radius: 30px;
            border: 1px solid #8692a6;
            height: 64px;
            padding: 30px 50px;
            background: transparent;
          }
        }

        & button {
          background: #2c73eb;
          height: 64px;
          border: none;
          color: #fff;
          text-align: center;
          font-family: Inter;
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          // border: ;
          border-radius: 30px;
          cursor: pointer;
        }
      }
    }
  }
}

.admin-dashboard {
  // border: 2px solid red;
  min-height: 746px;
  display: flex;
  flex-direction: row;
  height: fit-content;

  & .sidebar {
    padding-top: 278px;
    background: #e9ebed;
    width: 30%;
    max-width: 479px;
    // height: 1024px;
    height: auto;
    flex-shrink: 0;
    // padding: 28px 80px 60px 267px;
    // padding-left: 267px;
    // padding: 40px;
    display: flex;
    // justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 40px;

    & h1 {
      color: #474747;
      text-align: center;
      font-family: Open Sans;
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: 32px; /* 133.333% */
    }

    & .items {
      display: flex;
      flex-direction: column;
      // gap: 20px;

      & .item {
        color: #0089ff;
        font-family: Open Sans;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 22px; /* 157.143% */
        position: relative;
        text-decoration: none;
        padding: 10px 28px;
        border-top: 1px solid rgba(0, 0, 0, 0.1);
        cursor: pointer;

        &:before {
          content: "";
          width: 10px;
          height: 10px;
          position: absolute;
          top: 50%;
          transform: translate(-20px, -5px);
          border-radius: 10px;
          border: 1px solid #474747;
        }
      }

      & .active {
        &:before {
          background: #0089ff;
          border: none;
        }
      }
    }
  }

  & .center {
    padding: 228px 56px;
    display: flex;
    flex-direction: column;
    gap: 32px;
    width: 100%;
    height: fit-content;
    // border: 2px solid blue;
    background: #fafafa;
    position: relative;

    & .center-container {
      // border: 2px solid red;
    }

    & .dashboard-top {
      // border: 2px solid green;
      display: flex;
      justify-content: space-between;
      margin-bottom: 32px;

      & .add-btn {
        border-radius: 5px;
        background: #062644;
        color: var(--white, #fff);
        text-align: center;
        font-family: Inter;
        font-size: 13px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px; /* 153.846% */
        padding: 15px 28px;
        text-decoration: none;
        cursor: pointer;
        width: fit-content;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      & .newsletter-btn {
        color: #fff;
        font-family: Open Sans;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: 107%;
        background: #38b6ff;
        border: none;
        cursor: pointer;
        flex-shrink: 0;
        border-radius: 5px;
        padding: 22px 42px;
      }
    }
  }

  & .modal {
    position: fixed;
    // border: 2px solid indigo;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 4;

    & .modal-center {
      position: relative;
      border-radius: 5px;
      background: #fff;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      max-width: 381px;
      max-height: 324px;
      height: fit-content;
      padding: 23px 82px;
      // border: 2px solid red;
      box-shadow: 1px 1px 9px 5px rgb(237, 233, 233);
      gap: 32px;

      & .icon {
        width: 71px;
        height: 71px;
        flex-shrink: 0;
        color: #4bb543;
        font-size: 32px;
        background: #eee;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 36px;
        // border: 2px solid red;
      }

      & p {
        color: #000;
        text-align: center;
        font-family: Open Sans;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }

      & .buttons {
        display: flex;
        flex-direction: column;
        gap: 18px;

        & .button {
          border-radius: 5px;
          background: #062644;
          padding: 10px 28px;
          color: var(--white, #fff);
          text-align: center;
          font-family: Open Sans;
          font-size: 13px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px; /* 153.846% */
          border: none;
          text-decoration: none;
          min-width: 257px;
          cursor: pointer;
        }

        & .grey {
          background: #eee;
          color: var(--dark, #000);
        }
      }

      & .close-btn {
        position: absolute;
        right: 18px;
        top: 20px;
        width: 29px;
        height: 29px;
        background: #eee;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        cursor: pointer;
      }
    }
  }

  & .newsletter-modal {
    // border: 2px solid blue;
    margin-top: 100px;

    & .modal-center {
      padding: 50px 40px;
      max-width: 689px;
      max-height: 480px;
      width: 50%;

      & .input {
        width: 100%;
        border-radius: 5px;

        & input {
          border-radius: 5px;
          border: 1px solid var(--Neutral-gray30, #c6c6c6);
          background: var(--Neutral-gray10, #fafafa);
          padding: 20px;
          text-align: center;
          width: 100%;
          text-orientation: upright;
          overflow-wrap: normal;
          color: #8c8c8c;
          text-align: center;
          font-family: Inter;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 22px;

          &::placeholder {
            color: #8c8c8c;
            opacity: 0.9;
          }
        }
      }
    }
  }

  & .top-btns {
    // border: 2px solid blue;
    display: flex;
    gap: 105px;
    align-items: center;
    height: 24px;

    & .options {
      display: flex;
      align-items: center;
      gap: 22px;

      & .delete {
        cursor: pointer;
      }
    }
  }
}
