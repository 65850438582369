.career {
  @media (max-width: $breakpoint-tablet) {
  }
  & .career-section-two {
    // border: 2px solid red;
    margin: 160px 0;
    display: flex;
    flex-direction: column;
    gap: 110px;
    position: relative;
    z-index: 3;

    & .content {
      width: 80%;
      max-width: 1443px;
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      gap: 100px;

      & .career-item {
        // border: 2px solid blue;
        display: flex;
        gap: 62px;

        @media (max-width: $breakpoint-tablet) {
          flex-direction: column-reverse;
        }

        & .left {
          display: flex;
          flex-direction: column;
          gap: 20px;
          //   min-width: 527px;
          justify-content: center;

          & h1 {
            color: #474747;
            font-family: Open Sans;
            font-size: 50px;
            font-style: normal;
            font-weight: 600;
            line-height: 107%; /* 53.5px */
            // overflow: hidden;
          }

          & .line {
            height: 1px;
            background: #474747;
            width: 85px;
          }

          & h2 {
            color: #000;
            font-family: Open Sans;
            font-size: 20px;
            font-style: normal;
            font-weight: 400;
            line-height: 107%; /* 21.4px */
          }

          & p {
            color: #000;
            text-align: justify;
            font-family: Open Sans;
            font-size: 20px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
          }

          & h3 {
            color: #38b6ff;
            font-family: Open Sans;
            font-size: 20px;
            font-style: normal;
            font-weight: 400;
            line-height: 107%; /* 21.4px */
          }

          & ul {
            // list-style-type: none;
            list-style-position: inside;

            & li {
              color: #000;
              text-align: justify;
              font-family: Open Sans;
              font-size: 20px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
            }
          }

          & button,
          a {
            color: #38b6ff;
            text-decoration: none;
            font-family: Open Sans;
            font-size: 20px;
            font-style: normal;
            font-weight: 800;
            line-height: 107%; /* 21.4px */
            width: fit-content;
            border: none;
            background: transparent;
            display: flex;
            align-items: center;
            gap: 20px;
            cursor: pointer;
          }
        }

        

        & .right {
          min-width: 53%;
          //   width: 100%;
          //   max-width: 855px;
          overflow: hidden;

//           @media (max-width: $breakpoint-tablet) {
//             // flex-direction: column-reverse;
//             width: 367px;
// height: 317.208px;
//           }
          & img {
            height: 100%;
            width: 100%;
            object-fit: cover;
          }
        }
      }

      & .right-item{
        @media (max-width: $breakpoint-tablet) {
          flex-direction: column;
        }
          // flex-direction: column-reverse;

      }
    }

    & .join {
      max-width: 1082px;
      margin: 0 auto;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      gap: 30px;

          @media (max-width: $breakpoint-tablet) {
            align-items: flex-start;
          }


      & h1 {
        color: #474747;
        font-family: Open Sans;
        font-size: 50px;
        font-style: normal;
        font-weight: 600;
        line-height: 107%; /* 53.5px */
      }

      & p {
        color: #000;
        text-align: center;
        font-family: Open Sans;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;

          @media (max-width: $breakpoint-tablet) {
            text-align: justify;
          }
      }

      & button {
        width: fit-content;
        cursor: pointer;
        border: none;
        background: #38b6ff;
        color: #fff;
        font-family: Open Sans;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: 107%; /* 21.4px */
        padding: 22px 43px;
      }
    }
  }
}
