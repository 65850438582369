.error {
  padding: 250px 0;
  & .center {
    display: flex;
    flex-direction: column;
    gap: 30px;
    width: 80%;
    max-width: 1483px;
    margin: 0 auto;

    & h1 {
      font-size: 30px;
      font-weight: 700;
      color: #062644;
    }

    & a {
      border: none;
      background: #062644;
      color: white;
      border-radius: 10px;
      text-align: center;
      padding: 20px 30px;
      font-size: 20px;
      text-decoration: none;
      cursor: pointer;
      width: fit-content;
    }

    & img {
      width: 100%;
      height: 100%;
    }
  }
}
