.create-post {
  height: 100%;
  // width: 100%;
  max-width: 610px;
  width: 70%;
  margin: 0 auto;

  & form {
    display: flex;
    flex-direction: column;
    gap: 20px;
    // border: 2px solid red;
    height: fit-content;

    & input {
      border-radius: 5px;
      border: 1px solid var(--neutral-gray-30, #c6c6c6);
      background: var(--neutral-gray-10, #fafafa);
      padding: 20px;

      &::placeholder {
        color: #717171;

        /* Body/Regular -14pt */
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px; /* 157.143% */
      }
    }

    & .textarea {
      // border: 2px solid blue;
      height: 228px;
      resize: none;
    }

    & .form-item {
      display: flex;
      flex-direction: column;
      gap: 5px;

      & label {
        color: #000;
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 22px; /* 157.143% */
      }
    }

    & .date-item {
      position: relative;

      & .custom {
        border-radius: 5px;
        border: 1px solid var(--neutral-gray-30, #c6c6c6);
        background: var(--neutral-gray-10, #fafafa);
        padding: 20px;
        height: 60px;
        color: var(--neutral-gray-60, #8c8c8c);
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 22px; /* 157.143% */
        display: flex;
        justify-content: space-between;
      }

      & input {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 60px;
        // height: 100%;
        opacity: 0;
        z-index: 2;
      }
    }

    & .image-item {
      position: relative;

      & label {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 64px;
        border-radius: 10px;
        border: 1px solid #062644;
        background: #fafafa;

        & img {
          // border: 2px solid red;
          width: 75.03px;
          height: 80.957px;
          object-fit: cover;
        }

        & p {
          margin-bottom: 28px;
          color: #555;
          text-align: center;
          font-family: Inter;
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 24px; /* 150% */
        }

        & button {
          border-radius: 5px;
          background: #062644;
          box-shadow: 0px 8px 30px 0px rgba(0, 191, 178, 0.1);
          padding: 14px 35px;
          border: none;
          color: #fff;
          text-align: center;
          font-family: Inter;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 22px; /* 157.143% */
        }
      }

      & input {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        opacity: 0;
        cursor: pointer;
      }
    }

    & .background {
      & label {
        // background: transparent;
        background: rgba(6, 38, 68, 0.88);

        & p {
          color: #fff;
        }
      }
    }

    & .header {
      & label {
        color: #000;
        font-family: Inter;
        font-size: 30px;
        font-style: normal;
        font-weight: 600;
        line-height: 40px; /* 133.333% */
        letter-spacing: -0.6px;
      }
    }

    & .buttons {
      margin-top: 45px;
      width: 100%;
      display: flex;
      justify-content: flex-end;
      gap: 20px;

      & button {
        border-radius: 5px;
        background: #062644;
        box-shadow: 0px 8px 30px 0px rgba(0, 191, 178, 0.1);
        padding: 14px 28px;
        color: #fff;
        text-align: center;

        /* Body/Medium -14pt */
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 22px; /* 157.143% */
        width: 190px;
        cursor: pointer;
        border: none;
      }

      & .discard {
        background: #eee;
        color: #000;
      }

      & .disabled {
        cursor: not-allowed;
      }
    }
  }

  & .modal {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 4;

    & .modal-center {
      position: relative;
      border-radius: 5px;
      background: #fff;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      max-width: 381px;
      max-height: 324px;
      height: fit-content;
      padding: 23px 82px;
      // border: 2px solid red;
      box-shadow: 1px 1px 9px 5px rgb(237, 233, 233);
      gap: 32px;

      & .icon {
        width: 71px;
        height: 71px;
        flex-shrink: 0;
        color: #4bb543;
        font-size: 32px;
        background: #eee;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 36px;
        // border: 2px solid red;
      }

      & p {
        color: #000;
        text-align: center;
        font-family: Open Sans;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }

      & .button {
        border-radius: 5px;
        background: #062644;
        padding: 10px 28px;
        color: var(--white, #fff);
        text-align: center;
        font-family: Open Sans;
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 153.846% */
        border: none;
        text-decoration: none;
      }

      & .close-btn {
        position: absolute;
        right: 18px;
        top: 20px;
        width: 29px;
        height: 29px;
        background: #eee;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
      }
    }
  }
}
